import { Link } from "gatsby";
import React from "react";

const Card = ({ slug, tags, title, excerpt, date }) => {
  return (
    <div className="row">
      <div className="col">
        <Link
          style={{ fontSize: "1.2em", textDecoration: "underline" }}
          className="p-0 m-0"
          to={`/posts/${slug}`}
        >
          <h3 className="d-inline-block title-link">{title}</h3>
        </Link>
        {/* <p className="py-1">Date: {date}  </p> */}
        <ul className="p-0 mb-0" style={{ display: "flex" }}>
          {tags.map((tag) => (
            <li
              key={`${slug}-${tag}`}
              className="px-2 py-1 me-2"
              style={{
                border: "1px solid #1c7da3",
                borderRadius: "10px",
                listStyle: "none",
                fontSize: "0.7em",
                display: "flex",
              }}
            >
              <div className="col">
                <p className="p-0 m-0 tag" style={{ color: "#1c7da3" }}>
                  {tag}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Card;
